import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import store from './store';
import i18n from './i18n'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPhone, faCircleInfo, faStar, faXmark, faPaperPlane, faPaperclip, faChevronLeft, faChevronRight, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { faStar as faStar2 } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { EventBus } from '@/functions/eventbus';
import VueCarousel from 'vue-carousel';
import vuetify from './plugins/vuetify'

library.add(faPhone, faCircleInfo, faStar, faStar2, faXmark, faPaperPlane, faPaperclip, faChevronLeft, faChevronRight, faPlus, faMinus)

Vue.config.productionTip = false

const appRouter = () => import(`./router`);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueCarousel);
Vue.use(VueRouter);

window.attachmentUploadDidBegin = () => {
  console.log('window.attachmentUploadDidBegin called');
  window.$store.commit('ATTACHMENT_IS_UPLOADING', true);
};

window.attachmentUploadDidEnd = () => {
  console.log('window.attachmentUploadDidEnd called');
  window.$store.commit('ATTACHMENT_IS_UPLOADING', false);
};

(async () => {
  const routes = (await appRouter()).routes;

  const router = new VueRouter({ mode: 'history', routes });

  router.afterEach((route) => {
    if (route.name === 'home') {
      EventBus.$emit('refreshCases', true);
    }
  });

  new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
  }).$mount('#app')

})();
