import { getAuthorizedApiCall} from './http';

//init flow
export const getHello = (token) => getAuthorizedApiCall(`_/hello`, token);
export const getCases = (token, base64String) => getAuthorizedApiCall(`case?filter=${base64String}`, token);
export const getCaseConversations = (token, caseId) => getAuthorizedApiCall(`case/${caseId}/conversation`, token);

//agent selection
export const getBranchTopic = (branchId) => getAuthorizedApiCall(`branch/${branchId}/topic`);
export const getBranchRole = (branchId) => getAuthorizedApiCall(`branch/${branchId}/role`);
export const getRole = (roleId) => getAuthorizedApiCall(`role/${roleId}/agent`);

//communation flow
export const postCase = (token, data) => getAuthorizedApiCall(`case`, token, {method: 'POST', data});
export const postConversation = (token, data) => getAuthorizedApiCall(`conversation`, token, {method: 'POST', data});
export const postMessage = (token, data) => getAuthorizedApiCall(`message`, token, {method: 'POST', data});
export const getConversation = (token, conversationId) => getAuthorizedApiCall(`conversation/${conversationId}/message`, token);

export const getCaseAgent = (token, agentId) => getAuthorizedApiCall(`agent/${agentId}`, token);
export const getMessage = (token, messageId) => getAuthorizedApiCall(`message/${messageId}`, token);

export const postFavorite = (token, data) => getAuthorizedApiCall(`favourite`, token, {method: 'POST', data});
export const postUnfavorite = (token, data) => getAuthorizedApiCall(`_/unfavourite`, token, {method: 'POST', data});
export const getFavorite = (token) => getAuthorizedApiCall(`_/favourite`, token);
export const getCase = (token, caseId) => getAuthorizedApiCall(`case/${caseId}`, token);
export const postCaseWithId = (token, caseId, data) => getAuthorizedApiCall(`case/${caseId}`, token, {method: 'POST', data})