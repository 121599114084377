<template>
  <div id="app">
    <div :class="{ sidebar: true, conversations: true, invisible: sidebar }">
      <h2>{{ $t('title-customer-service') }}</h2>
      <router-view name="sidebar"></router-view>
    </div>

    <div :class="{ 'conversation-window': true, visible: container }">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { io } from 'socket.io-client';
import { EventBus } from './functions/eventbus';
import { mapState } from 'vuex';
const config = require('./config').default;

export default {
  computed: {
    ...mapState(['appLoading']),
    hasSelectedConversation() {
      return this.$store.state.sidebar.selectedCaseId !== null;
    },
    sidebar() {
      return !this.$route.meta.sidebar;
    },
    container() {
      return this.$route.meta.default;
    },
  },
  created() {
    window.$store = this.$store;
    this.$store.dispatch('getSupportsAttachments');
    this.$store.dispatch('getSupportsLinks');
    this.$store
      .dispatch('getTempToken')
      .then((tempToken) => {
        this.$store.dispatch('getHello', tempToken);
      })
      .catch((err) => console.error(err));

    EventBus.$on('setMe', this.socketIoConnection);

    EventBus.$on('caseLoaded', this.redirectCase);
  },
  methods: {
    checkSidebarHeightAction(type) {
      let sidebar = document.getElementsByClassName('sidebar');
      let sidebarHeight = 0;

      if (sidebar.length) {
        let childHeight = sidebar[0].firstChild.clientHeight;
        sidebarHeight = sidebar[0].clientHeight - childHeight;

        for (let i = 0; i < sidebar[0].children.length; i++) {
          sidebar[0].children[i].style.height = '';
        }

        console.log(type);
        sidebar[0].children[1].style.height = sidebarHeight + 'px';
      }
    },
    socketIoConnection() {
      this.socket = io(config.socketioUrl, {
        reconnection: true,
        reconnectionDelay: 500,
        reconnectionAttempts: 10,
      });

      this.socket.on('connect', () => {
        this.socket.on('hello', (result) => {
          this.$store.commit('SET_ONLINE_AGENTS', result);
        });

        this.socket.on('agent_connected', (result) => {
          this.$store.commit('SET_ONLINE_AGENTS', result);
        });

        this.socket.on('agent_disconnected', (result) => {
          this.$store.commit('SET_ONLINE_AGENTS', result);
        });

        this.socket.emit('login', { customerId: this.$store.state.me?.id }, (response) => {
          console.error('socket response', response);
        });
      });
    },
    redirectCase() {
      let params = new URLSearchParams(window.location.search);
      const openingCaseId = params.get('openingCaseId');

      if (openingCaseId) {
        EventBus.$emit('check-sidebar-height', 'conversation-list');
        this.$store.commit('SELECT_CASE', openingCaseId);

        this.$router.push({ name: 'conversation', params: { id: openingCaseId } });
      }
    },
  },
};
</script>
<style lang="scss">
@import './assets/scss/main.scss';
</style>
